import React from 'react';
import './Notfound.css';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const Notfound = () => {
    return (
        <div className="parent"> 
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        <Player
        autoplay
        loop
        src="https://assets3.lottiefiles.com/packages/lf20_3e3pakgj.json"
        style={{ height: '380px', width: '380px', background:'transparent', speed: '1' }}
        >
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
        <h1>Coming soon...</h1>
        <p>dev@webapp.wtf</p>
        </div>
    )
}

export default Notfound;