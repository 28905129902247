import './App.css';
import {Notfound} from './pages';

function App() {
  return (
    <div className="App">
      <Notfound />
    </div>
  );
}

export default App;
